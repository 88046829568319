<template>
  <button
      v-on:click="buttonAction()"
      type="button"
      :class="[
      'btn sam-primary-button mt-0',
      { 'mb-4': !noSpace && !customClass.includes('mb-') },
      customClass,
      { 'alert-button': alertButton }
    ]"
      :disabled="disabled === true"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'SamPrimaryButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    noSpace: {
      type: Boolean,
      default: false,
    },
    alertButton: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    buttonAction() {
      this.$emit('buttonAction');
    },
  }
}
</script>

<style lang="scss" scoped>
.btn.sam-primary-button, .btn.sam-primary-button:focus {
  background-color: $almostBlack;
  color: $white;
  border: none;
  height: $regularButtonHeight;
  width: $regularButtonWidth;
  transition: $mainTransition;
  font-family: $boldFontStyle;
  border-radius: $mainRadius;
}

.btn.sam-primary-button.alert-button, .btn.sam-primary-button.alert-button:focus {
  background-color: $alertRed;
}

.sam-primary-button:hover {
  opacity: $hoverOpacity;
}

.sam-primary-button:disabled {
  opacity: $disableOpacity;
}
</style>
